import React, { Component } from "react";

import string from "../../string";
import color from "../../color";

class UserLevelColor extends Component {
  render() {
    const { className, style, data } = this.props;

    return (
      <div
        className={className}
        style={{
          ...style,
          color: data.level === 3 ? color.m4 : color.gold
        }}
      >
        {string.userLevelCode[data.level]}
      </div>
    );
  }
}

export default UserLevelColor;
