import React, { Component } from "react";

import { DatePicker, Input, Table } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import moment from "moment";

import { httpGet, httpUrl } from "../../api/httpClient";
import {
  amountDivide,
  endDateFormat,
  formatDate,
  startDateFormat
} from "../../util";
import string from "../../string";
import SelectBox from "../../components/common/SelectBox";
import UserDetail from "../../components/user/UserDetail";
import RollupLogSelect from "../../components/rollup/RollupLogSelect";
import RollupLogHoldSelect from "../../components/rollup/RollupLogHoldSelect";

const Search = Input.Search;
const RangePicker = DatePicker.RangePicker;
class RollupLog extends Component {
  state = {
    list: [],
    pagination: {
      total: 0,
      current: 0
    },
    type: "ALL",
    statusType: "ALL",
    startDate: "",
    endDate: "",
    searchText: "",

    visible: false
  };

  // 페이징 처리
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({ pagination: pager });

    this.getRollupLog({ pageNum: pager.current });
  };

  // 검색 기간 설정
  handleChangeDate = (date) => {
    this.setState({
      startDate: date.length !== 0 ? startDateFormat(date[0]) : "",
      endDate: date.length !== 0 ? endDateFormat(date[1]) : ""
    });
  };

  // 타입 설정
  typeHandleChange = (value) => {
    this.setState({
      type: value
    });
  };

  // 상태 설정
  statusTypeHandleChange = (value) => {
    this.setState({
      statusType: value
    });
  };

  // 마감일 제한
  disabledDate = (current) => {
    // Can not select days before today and today
    return current > moment().endOf("day" - 1);
  };

  // 검색
  onSearch = (value) => {
    this.setState(
      {
        searchText: value
      },
      () => {
        this.getRollupLog();
      }
    );
  };

  // 롤업내역
  getRollupLog = (params = {}) => {
    const { type, statusType, startDate, endDate, searchText } = this.state;
    let pageNum = params.pageNum || 1;

    httpGet(
      httpUrl.getRollupLog,
      [10, pageNum, type, statusType, startDate, endDate, searchText],
      {}
    )
      .then((res) => {
        // console.log(`rollup res = ${JSON.stringify(res, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.list === null ? [] : res.data.list,
          pagination
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getRollupLog();
  }

  render() {
    const {
      userVisible,
      userData,
      visible,
      idx,
      rewardType,
      type,
      statusType,
      list,
      pagination
    } = this.state;
    const dateFormat = "YYYY-MM-DD";

    const columns = [
      {
        key: "idx",
        title: "날짜",
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      // {
      //   title: "타입",
      //   dataIndex: "type",
      //   className: "text-center",
      //   render: (data) => <div>{string.rollupType[data]}</div>
      // },
      {
        title: "ID",
        dataIndex: "id",
        className: "text-center",
        render: (data, row) => (
          <div
            className="pointer"
            onClick={() => {
              this.setState({
                userVisible: true,
                userData: {
                  id: row.id,
                  idx: row.userIdx,
                  recommendCode: row.recommendCode
                }
              });
            }}
          >
            {`${data} (${row.name})`}
          </div>
        )
      },
      {
        title: () => (
          <div>
            <div>롤업</div>
            <div>(건수 / 수량)</div>
          </div>
        ),
        dataIndex: "rollupAmount",
        className: "text-center",
        render: (data, row) =>
          data === 0 ? (
            <div>
              {row.rollupCount} / {amountDivide(data)}
            </div>
          ) : (
            <div
              className="pointer"
              onClick={() => {
                this.setState({
                  visible: true,
                  idx: row.idx,
                  rewardType: "ROLL_UP"
                });
              }}
            >
              {row.rollupCount} / {amountDivide(data)}
            </div>
          )
      },
      {
        title: () => (
          <div>
            <div>추천 롤업</div>
            <div>(건수 / 수량)</div>
          </div>
        ),
        dataIndex: "recRollupAmount",
        className: "text-center",
        render: (data, row) =>
          data === 0 ? (
            <div>
              {row.recRollupCount} / {amountDivide(data)}
            </div>
          ) : (
            <div
              className="pointer"
              onClick={() => {
                this.setState({
                  visible: true,
                  idx: row.idx,
                  rewardType: "REC_ROLL_UP"
                });
              }}
            >
              {row.recRollupCount} / {amountDivide(data)}
            </div>
          )
      },
      {
        title: () => (
          <div>
            <div>보류</div>
            <div>(건수 / 수량)</div>
          </div>
        ),
        dataIndex: "sideAmount",
        className: "text-center",
        render: (data, row) =>
          data === 0 ? (
            <div>
              {row.sideCount} / {amountDivide(data)}
            </div>
          ) : (
            <div
              className="pointer"
              onClick={() => {
                this.setState({
                  visible: true,
                  idx: row.idx,
                  rewardType: "HOLD"
                });
              }}
            >
              {row.sideCount} / {amountDivide(data)}
            </div>
          )
      },
      {
        title: "지급제외",
        dataIndex: "changeAmount",
        className: "text-center",
        render: (data) => <div>{amountDivide(data)}</div>
      },
      {
        title: "상태",
        dataIndex: "status",
        className: "text-center",
        render: (data) => <div>{string.rollupStatusString[data]}</div>
      }
    ];

    return (
      <div>
        {/* 회원정보 */}
        {userVisible && (
          <UserDetail
            visible={userVisible}
            userData={userData}
            type="user"
            page="rollup"
            onCancel={() => {
              this.setState({
                userVisible: false
              });
            }}
            refresh={this.getUserList}
          />
        )}
        {/* 상세내역 */}
        {visible && rewardType !== "HOLD" && (
          <RollupLogSelect
            page="rollup"
            visible={visible}
            idx={idx}
            type={rewardType}
            onCancel={() => {
              this.setState({ visible: false });
            }}
          />
        )}
        {visible && rewardType === "HOLD" && (
          <RollupLogHoldSelect
            page="rollup"
            visible={visible}
            idx={idx}
            type={rewardType}
            onCancel={() => {
              this.setState({ visible: false });
            }}
          />
        )}
        <div className="title">롤업 내역</div>
        <div className="flex-row flex-center m-b-10 flex-end">
          {/* <label htmlFor="type">
            타입&nbsp;
            <SelectBox
              value={type}
              style={{ width: "160px", marginRight: "10px" }}
              code={string.rollupRequestType}
              codeString={string.rollupRequestType}
              onChange={(value) => {
                if (value !== type) {
                  this.typeHandleChange(value);
                }
              }}
            />
          </label> */}
          <label htmlFor="statusType">
            상태&nbsp;
            <SelectBox
              value={statusType}
              style={{ width: "160px", marginRight: "10px" }}
              code={string.rollupStatusCode}
              codeString={string.rollupStatusString}
              onChange={(value) => {
                if (value !== statusType) {
                  this.statusTypeHandleChange(value);
                }
              }}
            />
          </label>
          <label>검색기간&nbsp;</label>
          <RangePicker
            locale={locale}
            format={dateFormat}
            onChange={(date) => {
              this.handleChangeDate(date);
            }}
          />
          <Search
            placeholder={`아이디를 입력해주세요.`}
            enterButton="검색"
            allowClear
            onSearch={this.onSearch}
            style={{ width: 300, marginLeft: 10 }}
          />
        </div>
        <div style={{ clear: "both" }} />
        <Table
          id="member-table"
          className="flex-1"
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default RollupLog;
