import React, { Component } from "react";

import { Card, Col, Icon, Row, Statistic } from "antd";
import { httpGet, httpUrl } from "../../api/httpClient";

import { amountDivide, numberFormat } from "../../util";
import UserTotalCountColor from "../../components/statistics/UserTotalCountColor";
import UserLevelCountColor from "../../components/statistics/UserLevelCountColor";
import VirtualAccountDepositCard from "../../components/statistics/VirtualAccountDepositCard";
import StatisticsDiv from "../../components/statistics/StatisticsDiv";
import EventCard from "../../components/event/EventCard";
import string from "../../string";
import color from "../../color";
import EventStatistics from "../../components/statistics/EventStatistics";

class TotalStatistics extends Component {
  state = {
    levelData: {
      member: { count: 0 },
      owner: { count: 0 },
      total: { count: 0 },
      today: { count: 0 }
    },
    virtual: {
      avatar: {
        amount: 0,
        count: 0,
        todayAmount: 0,
        todayCount: 0
      },
      reg: {
        amount: 0,
        count: 0,
        todayAmount: 0,
        todayCount: 0
      },
      total: {
        amount: 0,
        count: 0,
        todayAmount: 0,
        todayCount: 0
      },
      upgrade: {
        amount: 0,
        count: 0,
        todayAmount: 0,
        todayCount: 0
      }
    },

    rank: []
  };

  componentDidMount() {
    this.getDashBoardLevel();
    this.getDashBoardRollup();
    this.getDashBoardRecRollup();
    this.getDashBoardHold();
    this.getDashBoardVirtualAccountDeposit();
  }

  render() {
    return (
      <div>
        <Row gutter={16} className="m-b-20">
          {/* 회원 현황 */}
          <Col
            span={8}
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.props.history.push({
                pathname: "/statistics/level",
                selectedMenu: "LEVEL_STATISTICS"
              });
            }}
          >
            {this.renderUser()}
          </Col>
          {/* 롤업현황 */}
          <Col
            span={8}
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.props.history.push({
                pathname: "/statistics/rollup",
                selectedMenu: "ROLL_UP_STATISTICS"
              });
            }}
          >
            {this.renderRollup()}
          </Col>
          {/* 추천롤업현황 */}
          <Col
            span={8}
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.props.history.push({
                pathname: "/statistics/recRollup",
                selectedMenu: "REC_ROLL_UP_STATISTICS"
              });
            }}
          >
            {this.renderRecRollup()}
          </Col>
        </Row>
        <Row gutter={16} className="m-b-20">
          {/* 지급보류 */}
          <Col span={8} style={{}}>
            {this.renderHold()}
          </Col>
          <Col
            span={8}
            className="m-b-20"
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.props.history.push({
                pathname: "/statistics/virtualAccountDeposit",
                selectedMenu: "VAD_STATISTICS"
              });
            }}
          >
            {this.renderVirtualAccountDeposit()}
          </Col>
        </Row>
      </div>
    );
  }

  // 현황 통계
  getDashBoardLevel = () => {
    httpGet(httpUrl.getDashBoardLevel, [], {})
      .then((res) => {
        // console.log(`level res = ${JSON.stringify(res, null, 4)}`);
        let levelData = res.data;
        this.setState({
          levelData
        });
      })
      .catch((e) => {});
  };

  renderUser() {
    const { levelData } = this.state;

    return (
      <Card
        style={{
          border: "2px solid #A6A6A6",
          borderRadius: 20,
          minHeight: 340
        }}
      >
        <Statistic
          title=""
          className="title"
          value="회원 현황"
          style={{
            marginBottom: 30,
            textAlign: "left",
            paddingBottom: 20,
            borderBottom: "1px solid #A6A6A6"
          }}
        />
        <div className="flex-row flex-center flex-sa  m-b-20">
          <UserTotalCountColor title="총 등록 회원" data={levelData.total} />
          <UserTotalCountColor
            title="오늘 등록한 회원수"
            data={levelData.today}
          />
        </div>
        <div className="flex-row flex-center flex-sa m-b-10">
          <UserLevelCountColor level={3} data={levelData.member} />
          <UserLevelCountColor level={4} data={levelData.owner} />
        </div>
      </Card>
    );
  }

  // 롤업 현황 통계
  getDashBoardRollup = (params = {}) => {
    httpGet(httpUrl.getDashBoardRollup, [], {})
      .then((res) => {
        // console.log(`rec rollup res = ${JSON.stringify(res, null, 4)}`);
        this.setState({
          rollupCount: res.data.count,
          rollupAmount: res.data.amount,
          rollupTodayCount: res.data.todayCount,
          rollupTodayAmount: res.data.todayAmount
        });
      })
      .catch((e) => {});
  };

  renderRollup() {
    const { rollupCount, rollupAmount, rollupTodayCount, rollupTodayAmount } =
      this.state;
    return (
      <Card
        style={{
          border: "2px solid #A6A6A6",
          borderRadius: 20,
          minHeight: 340
        }}
      >
        <Statistic
          title=""
          className="title"
          value="롤업 현황"
          style={{
            textAlign: "left",
            marginBottom: 30,
            paddingBottom: 20,
            borderBottom: "1px solid #A6A6A6"
          }}
        />
        <StatisticsDiv title="총 건수" data={numberFormat(rollupCount)} />
        <StatisticsDiv title="총 수량" data={amountDivide(rollupAmount)} />
        <StatisticsDiv
          title="오늘 건수"
          data={numberFormat(rollupTodayCount)}
        />
        <StatisticsDiv
          title="오늘 수량"
          data={amountDivide(rollupTodayAmount)}
        />
      </Card>
    );
  }

  // 추천롤업 현황 통계
  getDashBoardRecRollup = (params = {}) => {
    httpGet(httpUrl.getDashBoardRecRollup, [], {})
      .then((res) => {
        // console.log(`rec rollup res = ${JSON.stringify(res, null, 4)}`);
        this.setState({
          recRollupCount: res.data.count,
          recRollupAmount: res.data.amount,
          recRollupTodayCount: res.data.todayCount,
          recRollupTodayAmount: res.data.todayAmount
        });
      })
      .catch((e) => {});
  };

  renderRecRollup() {
    const {
      recRollupCount,
      recRollupAmount,
      recRollupTodayCount,
      recRollupTodayAmount
    } = this.state;

    return (
      <Card
        style={{
          border: "2px solid #A6A6A6",
          borderRadius: 20,
          minHeight: 340
        }}
      >
        <Statistic
          title=""
          className="title"
          value="추천 롤업 현황"
          style={{
            textAlign: "left",
            marginBottom: 30,
            paddingBottom: 20,
            borderBottom: "1px solid #A6A6A6"
          }}
        />
        <StatisticsDiv title="총 건수" data={numberFormat(recRollupCount)} />
        <StatisticsDiv title="총 수량" data={amountDivide(recRollupAmount)} />
        <StatisticsDiv
          title="오늘 건수"
          data={numberFormat(recRollupTodayCount)}
        />
        <StatisticsDiv
          title="오늘 수량"
          data={amountDivide(recRollupTodayAmount)}
        />
      </Card>
    );
  }

  // 현황 통계
  getDashBoardShare = () => {
    httpGet(httpUrl.getDashBoardShare, [], {})
      .then((res) => {
        // console.log(`share res = ${JSON.stringify(res, null, 4)}`);
        this.setState({
          shareAmount: res.data.amount,
          shareWeeklyPayment: res.data.weeklyPayment
        });
      })
      .catch((e) => {});
  };

  renderShare() {
    const { shareAmount, shareWeeklyPayment } = this.state;

    return (
      <Card
        style={{
          border: "2px solid #A6A6A6",
          borderRadius: 20,
          minHeight: 300
        }}
      >
        <Statistic
          title=""
          className="title"
          value="공유수당 현황"
          style={{
            marginBottom: 30,
            textAlign: "left",
            paddingBottom: 20,
            borderBottom: "1px solid #A6A6A6"
          }}
        />
        <StatisticsDiv title="총 수량" data={amountDivide(shareAmount)} />
        <StatisticsDiv
          title="금주 지급 예정"
          data={amountDivide(shareWeeklyPayment)}
        />
      </Card>
    );
  }

  // 현황 통계
  getDashBoardHold = () => {
    httpGet(httpUrl.getDashBoardHold, [], {})
      .then((res) => {
        // console.log(`hold res = ${JSON.stringify(res, null, 4)}`);
        this.setState({
          holdRollupAmount: res.data.rollupAmount,
          holdRollupCount: res.data.rollupCount,
          holdRecRollupAmount: res.data.recRollupAmount,
          holdRecRollupCount: res.data.recRollupCount,
          holdAmount: res.data.holdAmount,
          holdCount: res.data.holdCount
        });
      })
      .catch((e) => {});
  };

  renderHold() {
    const {
      holdRollupCount,
      holdRollupAmount,
      holdRecRollupCount,
      holdRecRollupAmount,
      holdCount,
      holdAmount
    } = this.state;

    return (
      <Card
        style={{
          border: "2px solid #A6A6A6",
          borderRadius: 20,
          minHeight: 340
        }}
      >
        <Statistic
          title=""
          className="title"
          value="지급보류 현황"
          style={{
            marginBottom: 30,
            textAlign: "left",
            paddingBottom: 20,
            borderBottom: "1px solid #A6A6A6"
          }}
        />
        <StatisticsDiv
          title="롤업 건수 / 수량"
          style={{ width: 200 }}
          data={`${numberFormat(holdRollupCount)} / ${amountDivide(
            holdRollupAmount
          )}`}
        />
        <StatisticsDiv
          title="추천 롤업 건수 / 수량"
          style={{ width: 200 }}
          data={`${numberFormat(holdRecRollupCount)} / ${amountDivide(
            holdRecRollupAmount
          )}`}
        />
        <StatisticsDiv
          title="지급 중단"
          style={{ width: 200 }}
          data={`${numberFormat(holdCount)} / ${amountDivide(holdAmount)}`}
        />
      </Card>
    );
  }

  // 이벤트
  getDashBoardEvent = () => {
    httpGet(httpUrl.getDashBoardEvent, [], {})
      .then((res) => {
        // console.log(`event res = ${JSON.stringify(res, null, 4)}`);
        this.setState({
          eventAmount: res.data.amount,
          eventPayment: res.data.monthlyPayment,
          rank: res.data.rank
        });
      })
      .catch((e) => {});
  };

  renderEvent() {
    const { eventAmount, eventPayment, rank } = this.state;
    return (
      <Card
        style={{
          border: "2px solid #A6A6A6",
          borderRadius: 20,
          minHeight: 300
        }}
      >
        <Statistic
          title=""
          className="title"
          value="이벤트 적립 현황"
          style={{
            marginBottom: 20,
            textAlign: "left",
            paddingBottom: 20,
            borderBottom: "1px solid #A6A6A6"
          }}
        />
        <div className="flex-row flex-center">
          <div
            className="pointer"
            style={{
              border: "1px solid",
              borderColor: color.lightgray,
              padding: 10,
              minWidth: 120,
              borderRadius: 5,
              marginRight: 10
            }}
            onClick={() => {
              this.props.history.push({
                pathname: "/event/log",
                selectedMenu: "EVENT_LOG"
              });
            }}
          >
            <EventStatistics
              title="누적 수량"
              data={amountDivide(eventAmount)}
            />
            <EventStatistics
              title="이번달 지급 예정"
              data={amountDivide(eventPayment)}
            />
          </div>
          {rank.length !== 0 ? (
            <div
              className="pointer"
              style={{
                border: "1px solid",
                borderColor: color.lightgray,
                padding: 10,
                minWidth: 300,
                borderRadius: 5
              }}
              onClick={() => {
                this.props.history.push({
                  pathname: "/event/rank",
                  selectedMenu: "RECOMMEND_RANK"
                });
              }}
            >
              {rank.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="flex-row flex-center flex-sa"
                    style={{ marginBottom: rank.length - 1 === index ? 0 : 10 }}
                  >
                    <div>{index + 1}</div>
                    <div
                      className="m-r-10 text-center"
                      style={{
                        width: 70,
                        color: item.level === 3 ? color.m4 : color.gold
                      }}
                    >
                      {string.userLevelCode[item.level]}
                    </div>
                    <div style={{ width: 50 }}>{item.name}</div>
                    <div className="text-right" style={{ minWidth: 20 }}>
                      {item.score}
                    </div>
                    <div className="text-right" style={{ minWidth: 30 }}>
                      <Icon type="like" style={{ marginRight: 5 }} />
                      {item.count}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="level-title m-l-10">데이터가 없습니다</div>
          )}
        </div>
      </Card>
    );
  }

  getDashBoardVirtualAccountDeposit = () => {
    httpGet(httpUrl.getDashBoardVirtualAccountDeposit, [], {})
      .then((res) => {
        // console.log(`res = ${JSON.stringify(res, null, 4)}`);
        let virtual = res.data;
        this.setState({
          virtual
        });
      })
      .catch((e) => {});
  };

  renderVirtualAccountDeposit() {
    const { virtual } = this.state;

    return (
      <Card
        style={{
          border: "2px solid #A6A6A6",
          borderRadius: 20,
          minHeight: 340
        }}
      >
        <Statistic
          title=""
          className="title"
          value="입금 현황"
          style={{
            textAlign: "left",
            marginBottom: 30,
            paddingBottom: 20,
            borderBottom: "1px solid #A6A6A6"
          }}
        />
        <StatisticsDiv title="총 건수" data={numberFormat(virtual.reg.count)} />
        <StatisticsDiv
          title="총 수량"
          data={amountDivide(virtual.reg.amount)}
        />
        <StatisticsDiv
          title="오늘 건수"
          data={numberFormat(virtual.reg.todayCount)}
        />
        <StatisticsDiv
          title="오늘 수량"
          data={amountDivide(virtual.reg.todayAmount)}
        />
      </Card>
    );
  }
}

export default TotalStatistics;
