import React, { Component } from "react";

import { Icon } from "antd";

import color from "../../color";
import string from "../../string";
import { numberFormat } from "../../util";

class UserLevelCountColor extends Component {
  render() {
    const { style, level, data } = this.props;
    return (
      <div className="level-title" style={style}>
        <div
          style={{
            fontSize: 18,
            color: level === 3 ? color.m4 : color.gold
          }}
        >
          {string.userLevelCode[level]}
        </div>
        <div
          className="flex-row flex-center justify-center"
          style={{
            fontSize: 26,
            color: level === 3 ? color.m4 : color.gold
          }}
        >
          <Icon type="user" style={{ marginRight: 5 }} />
          {numberFormat(data.count)}
        </div>
      </div>
    );
  }
}

export default UserLevelCountColor;
