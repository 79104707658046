import Const from "./const";

const string = {
  // 회원
  memberSearchTypeCode: ["ID", "ADDRESS", "NAME", "PHONE"],
  memberSearchTypeString: {
    ID: "아이디",
    ADDRESS: "지갑주소",
    NAME: "이름",
    PHONE: "전화번호"
  },
  searchTypeString: {
    ID: "아이디",
    ADDRESS: "지갑주소"
  },
  orgChartSearchTypeString: {
    ID: "아이디",
    RECOMMEND_CODE: "추천코드"
  },
  userSearchTypeCode: ["ID", "NAME", "RECOMMENDER", "PATRON", "ADDRESS"],
  userSreachTypeString: {
    ID: "아이디",
    NAME: "이름",
    RECOMMENDER: "추천인",
    PATRON: "후원인",
    ADDRESS: "주소"
  },
  eventSearchTypeString: {
    NAME: "이름",
    RECOMMENDER: "추천인"
  },
  userLevel: ["", -1, 3, 4],
  userLevelCode: {
    "": "전체",
    [-1]: "미등록",
    3: "MEMBER",
    4: "OWNER"
  },
  // penton 지갑
  pntWalletTypeCode: [
    "ALL",
    "SWAP_WALLET",
    "SIDE_WALLET",
    "SAVING_WALLET",
    "SHARE_WALLET",
    "FEE_WALLET",
    "EVENT_WALLET",
    "CHANGE_WALLET"
    // "DEV_WALLET",
  ],
  pntWalletType: {
    ALL: "전체",
    SWAP_WALLET: "스왑 지갑",
    SIDE_WALLET: "보류 지갑",
    SAVING_WALLET: "포인트 지갑",
    SHARE_WALLET: "공유수당 지갑",
    FEE_WALLET: "수수료 지갑",
    EVENT_WALLET: "이벤트 지갑",
    CHANGE_WALLET: "낙전 지갑"
    // DEV_WALLET: "전산 지갑",
  },
  // rollup
  rollupRequestType: {
    ALL: "전체",
    REGISTRATION: "등록",
    UPGRADE: "업그레이드",
    AVATAR: "아바타"
  },
  rollupType: {
    ALL: "전체",
    REGISTRATION: "등록(M)",
    UPGRADE_1: "업그레이드(M1)",
    UPGRADE_2: "업그레이드(M2)",
    UPGRADE_3: "업그레이드(M4)",
    AVATAR_4: "아바타(Gold)",
    AVATAR_5: "아바타(Diamond)",
    AVATAR_6: "아바타(Penton)"
  },
  rollupStatusCode: [
    "ALL",
    "WAIT",
    "READY",
    "FEE_REQUEST",
    "FEE_PENDING",
    "FEE_SUCCESS"
  ],
  rollupStatusString: {
    ALL: "전체",
    WAIT: "입금대기",
    READY: "등록대기",
    FEE_REQUEST: "수수료지급중",
    FEE_PENDING: "수수료지급중",
    FEE_SUCCESS: "등록완료",
    CANCEL: "철회"
  },
  rollupTypeCode: ["ALL", "ROLL_UP", "REC_ROLL_UP"],
  rollupTypeString: {
    ALL: "전체",
    ROLL_UP: "롤업",
    ROLL_UP_SIDE: "롤업 보류",
    REC_ROLL_UP: "추천 롤업",
    REC_ROLL_UP_SIDE: "추천 롤업 보류",
    CHANGE: "지급제외",
    // SHARE: "공유",
    RECOMMEND: "추천"
  },
  rollupTypeSelectString: {
    ALL: "전체",
    ROLL_UP: "롤업",
    REC_ROLL_UP: "추천 롤업"
    // SHARE: "공유"
  },
  holdTypeString: {
    ALL: "전체",
    HOLD: "지급 보류"
  },
  // 지갑
  walletLogCode: ["ALL", "FROM", "TO"],
  transferSearchString: {
    ALL: "전체",
    FROM: "보낸 지갑주소",
    TO: "받은 지갑주소"
  },
  transfeCode: ["ALL", "I2I", "I2E", "E2I", "AIR", "P2P"],
  transferString: Const.isP2PFlag
    ? {
        ALL: "전체",
        I2I: "내부 전송",
        I2E: "외부 송금",
        E2I: "외부 입금",
        AIR: "에어드랍",
        P2P: "P2P 전송"
      }
    : {
        ALL: "전체",
        I2I: "내부 전송",
        I2E: "외부 송금",
        E2I: "외부 입금",
        AIR: "에어드랍"
      },
  typeCode: ["FROM", "TO"],
  cautionString: {
    FROM: "송신 제한",
    TO: "수신 제한"
  },
  activeCode: ["ALL", "ACTIVE", "NOT"],
  activeString: {
    ALL: "전체",
    ACTIVE: "활성화",
    NOT: "비활성화"
  },
  distributeCode: ["ALL", "MANUAL", "AUTO"],
  distributeString: {
    ALL: "전체",
    MANUAL: "수동",
    AUTO: "자동"
  },
  distributeTypeString: {
    MANUAL: "수동",
    LOGIN: "로그인",
    RECMND: "추천가입(추천자)",
    RECMNDED: "추천가입",
    NOT: "미추천가입"
  },
  // 통계
  partnerStatistics: {
    DAY: "일별",
    WEEK: "주별",
    MONTH: "월별"
  },
  shareStatistics: {
    WEEK: "주별",
    MONTH: "월별"
  },
  // 공지사항
  noticeCode: ["ALL", "NOTE", "EVNT", "NEWS"],
  noticeString: {
    ALL: "전체",
    NOTE: "공지사항",
    EVNT: "이벤트",
    NEWS: "소식"
  },
  noticeSelectCode: ["NOTE", "EVNT", "NEWS"],
  noticeSelect: {
    NOTE: "공지사항",
    EVNT: "이벤트",
    NEWS: "소식"
  },
  qnaCode: ["ALL", "SUCCESS", "REQUSET"],
  qnaString: {
    ALL: "전체",
    SUCCESS: "답변완료",
    REQUEST: "답변대기"
  },
  requestLogCode: ["ALL", "REQUEST", "SUCCESS", "PENDING", "FAIL"],
  requestLogString: {
    ALL: "전체",
    REQUEST: "요청",
    SUCCESS: "성공",
    PENDING: "전송 중",
    FAIL: "실패"
  },
  // eth 스왑
  swapLogString: {
    ALL: "ALL",
    REQUEST: "REQUEST",
    SUCCESS: "SUCCESS",
    PENDING: "PENDING",
    FAIL: "FAIL"
  },
  orderByCode: ["ASC", "DESC"],
  orderByString: {
    ASC: "정순",
    DESC: "역순"
  },
  lockRequestCode: ["SET_LOCK", "UN_LOCK"],
  lockRequesetString: {
    ALL: "전체",
    SET_LOCK: "LOCK",
    UN_LOCK: "UNLOCK"
  },
  lockRequestSearchCode: ["ID", "ADDRESS", "TXID"],
  lockRequesetSearchString: {
    ID: "아이디",
    ADDRESS: "지갑주소",
    TXID: "TXID"
  },
  adjustmentCode: ["ALL", "CREATED", "COMPLETE", "REJECT"],
  adjustmentString: {
    ALL: "전체",
    CREATED: "대기",
    COMPLETE: "지급완료",
    REJECT: "지급거절"
  },
  auditCode: ["ALL", "SUCCESS", "FAIL"],
  auditString: {
    ALL: "전체",
    SUCCESS: "성공",
    FAIL: "실패"
  },
  toggleCode: [0, 1],
  deleteString: {
    0: "정상",
    1: "삭제"
  },
  activeHandlesCodes: {
    0: "비활성화",
    1: "활성화"
  },
  adminString: {
    0: "일반관리자",
    1: "슈퍼관리자"
  },
  authString: {
    0: "불가능",
    1: "가능"
  },
  userString: {
    0: "-",
    1: "탈퇴"
  },
  holdFlagString: {
    0: "-",
    1: "지급 보류"
  }
};

export default string;
