import React, { Component } from "react";
import { Tree, TreeNode } from "react-organizational-chart";

import { Icon, Modal } from "antd";

import { httpGet, httpUrl } from "../../api/httpClient";
import color from "../../color";
import ChartCard from "../chart/ChartCard";

class OrganizationChart extends Component {
  state = {
    userData: {},
    prevUserData: [],
    list: []
  };

  // 회원정보 조직도
  getUserRecommendChart = (params = {}) => {
    const { userData } = this.props;
    let code = params.code || userData.recommendCode;

    httpGet(httpUrl.userRecommendChart, [code], {})
      .then((res) => {
        // console.log(`user chart res = ${JSON.stri/ngify(res, null, 4)}`);
        this.setState({
          list: res.data
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    // console.log(`props = ${JSON.stringify(this.props, null, 4)}`);
    this.getUserRecommendChart();
    this.setState({
      userData: this.props.userData
    });
  }

  render() {
    const { visible, onCancel } = this.props;
    const { userData, prevUserData, list } = this.state;

    const card = (props) => (
      <ChartCard
        data={props.data}
        className={
          userData.id !== props.data.id
            ? (props.data.rightCnt !== 0 || props.data.leftCnt !== 0) &&
              props.click
              ? "pointer"
              : "false"
            : "false"
        }
        onClick={() => {
          if (userData.id !== props.data.id) {
            if (  
              (props.data.rightCnt !== 0 || props.data.leftCnt !== 0) &&
              props.click
            ) {
              this.setState({
                prevUserData: [...prevUserData, this.state.userData],
                userData: props.data
              });
              this.getUserRecommendChart({
                code: props.data.recommendCode
              });
            }
          }
        }}
      />
    );

    const loop = (data) =>
      data.map((item) => {
        if (item.childs && item.childs.length)
          return (
            <TreeNode key={item.id} label={card({ data: item, click: false })}>
              {loop(item.childs)}
            </TreeNode>
          );
        return (
          <TreeNode key={item.id} label={card({ data: item, click: true })} />
        );
      });

    return (
      <Modal
        visible={visible}
        onCancel={onCancel}
        title={"조직도"}
        footer={null}
        style={{ minWidth: "1600px" }}
      >
        <div className="flex-row flex-center m-b-10">
          <label className="m-r-10">등급 :</label>
          <div className="font-bold font-16 m-r-10" style={{ color: color.m4 }}>
            MEMBER
          </div>
          <div
            className="font-bold font-16 m-r-10"
            style={{ color: color.gold }}
          >
            OWNER
          </div>
        </div>
        {prevUserData.length !== 0 && (
          <Icon
            type="left"
            className="m-b-20"
            onClick={() => {
              this.setState({
                userData: {
                  id: prevUserData[prevUserData.length - 1].id,
                  name: prevUserData[prevUserData.length - 1].name,
                  level: prevUserData[prevUserData.length - 1].level,
                  recommendCode:
                    prevUserData[prevUserData.length - 1].recommendCode,
                  leftCnt: prevUserData[prevUserData.length - 1].leftCnt,
                  rightCnt: prevUserData[prevUserData.length - 1].rightCnt,
                  recLeftCnt: prevUserData[prevUserData.length - 1].recLeftCnt,
                  recRightCnt: prevUserData[prevUserData.length - 1].recRightCnt
                }
              });
              this.getUserRecommendChart({
                searchText: prevUserData[prevUserData.length - 1].recommendCode
              });
              prevUserData.pop();
            }}
          />
        )}
        <div style={{ paddingBottom: 20, overflow: "auto" }}>
          <Tree
            className="flex-row flex-center justify-center"
            lineWidth={"1px"}
            lineColor={"#bbc"}
            lineBorderRadius={"20px"}
            key={userData.id}
            label={card({ data: userData })}
          >
            {loop(list)}
          </Tree>
        </div>
      </Modal>
    );
  }
}

export default OrganizationChart;
