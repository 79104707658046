import Axios from "axios";
import util from "util";
import Const from "../const";
import { reactLocalStorage } from "reactjs-localstorage";
let loadingCount = 0;

global.language = "ko";
global.lanList = ["ko", "en", "ja", "zh"];

const serverUrl =
  Const.serverProtocol + "://" + Const.serverIp + ":" + Const.serverPort;

const makeUrl = (url, params) => {
  var result = serverUrl + url;
  if (params === null) return result;
  params.forEach((param) => {
    result = util.format(result, param);
  });
  return result;
};

const httpExec = (method, url, data) => {
  loadingCount++;
  if (loadingCount === 1)
    global.document.getElementById("loadingSpinner").style.display = "block";

  return new Promise((resolve, reject) => {
    Axios({
      method: method,
      url: url,
      data: data,
      withCredentials: true,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then((response) => {
        // if (
        //   url === serverUrl + httpUrl.login ||
        //   url === serverUrl + httpUrl.logout
        // ) {
        // } else {
        //   if (
        //     (method === 'PUT' || method === 'POST' || method === 'DELETE') &&
        //     response.data.result === 'SUCCESS'
        //   ) {
        //     alert('완료');
        //   }
        // }
        resolve(response.data);
        loadingCount--;
        if (loadingCount === 0)
          global.document.getElementById("loadingSpinner").style.display =
            "none";
      })
      .catch((error) => {
        // console.log(JSON.stringify(error, null, 4));
        if (error.message.includes("401")) {
          alert("로그인이 만료되었습니다. 다시 로그인해주세요");
          reactLocalStorage.remove("adminUser");
          global.location.href = "/";
        }
        // if (error.response.data.message === 'E10003') {
        //   if (!isAlertOpened) {
        //     isAlertOpened = true;
        //     alert('장기간 미사용으로 자동 로그아웃 되었습니다.');
        //     global.location.href = '/';
        //   }
        // } else if (error.response.data.data === 'E10003') {
        //   if (!isAlertOpened) {
        //     isAlertOpened = true;
        //     alert('접근 권한이 없습니다. 로그인 해주세요.');
        //     global.location.href = '/';
        //   }
        // }
        // alert(JSON.stringify(error));
        reject(error);
        loadingCount--;
        if (loadingCount === 0)
          global.document.getElementById("loadingSpinner").style.display =
            "none";
      });
  });
};

const httpGet = (url, params, data) => {
  return httpExec("GET", makeUrl(url, params), data);
  // return new Promise((resolve, reject) => {
  //   Axios.get(makeUrl(url, params), data)
  //     .then(response => {
  //       resolve(response.data);
  //     })
  //     .catch(error => {
  //       reject(error);
  //     });
  // });
};

const httpPut = (url, params, data) => {
  return httpExec("PUT", makeUrl(url, params), data);
  // return new Promise((resolve, reject) => {
  //   Axios.put(makeUrl(url, params), data)
  //     .then(response => {
  //       resolve(response.data);
  //     })
  //     .catch(error => {
  //       reject(error);
  //     });
  // });
};

const httpPost = (url, params, data) => {
  return httpExec("POST", makeUrl(url, params), data);
  // return new Promise((resolve, reject) => {
  //   Axios.post(makeUrl(url, params), data)
  //     .then(response => {
  //       resolve(response.data);
  //     })
  //     .catch(error => {
  //       reject(error);
  //     });
  // });
};

const httpDownload = (url, params, data) => {
  // return httpExec('GET', makeUrl(url, params), data);
  return new Promise((resolve, reject) => {
    Axios({
      method: "GET",
      url: makeUrl(url, params),
      data: data,
      withCredentials: true,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      responseType: "arraybuffer"
    })
      .then((response) => {
        var blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        resolve(blob);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const httpUrl = {
  idPwdCheck: "/idPwCheck",
  login: "/login",
  logout: "/logout",

  //blockchain coin decimal
  getCoinDecimal: "/blockchain/list/decimal?language=%s",
  getSearchSerial: "/foundation/search?coinType=%s&address=%s",

  // statistics - 통계
  getDashBoardLevel: "/dashboard/levelUp",
  getDashBoardRollup: "/dashboard/rollup",
  getDashBoardRecRollup: "/dashboard/recRollup",
  getDashBoardShare: "/dashboard/share",
  getDashBoardHold: "/dashboard/reward/hold",
  getDashBoardEvent: "/dashboard/event",
  getDashBoardVirtualAccountDeposit: "/dashboard/virtualAccountDeposit",

  // user - 회원
  getUserList:
    "/user/list?pageSize=%s&pageNum=%s&searchText=%s&searchType=%s&level=%s",
  userSelect: "/user/select/%s",
  userUpdate: "/user/update",
  userHold: "/user/hold/switch/%s",
  userRecommendChart: "/user/%s",
  getUserWalletList: "/user/walletListByIdx?pageSize=%s&pageNum=%s&idx=%s",
  getUserRewardLog: "/user/select/reward/log?recommendCode=%s",
  getUserRewardLogList:
    "/user/select/reward/log/list?pageSize=%s&pageNum=%s&recommendCode=%s&rewardType=%s",
  getUserHoldLog: "/user/hold/%s",
  getUserHoldLogList:
    "/user/hold/to/log?pageSize=%s&pageNum=%s&recommendCode=%s&rewardType=%s&hold=%s",
  getUserRecommendList:
    "/user/select/recommend?pageSize=%s&pageNum=%s&recommendCode=%s",

  // orgChart - 조직도
  getRecommendUserData: "/recommend/user/search/%s?searchType=%s",
  getRecommendChart: "/recommend/%s",

  // pentwallet 지갑
  getPentonWalletList: "/marketingwallet/list",
  pentWalletAirdrop: "/marketingwallet/list/airdrop",
  getPentonWalletRequestList:
    "/marketingwallet/airdrop/list?pageSize=%s&pageNum=%s&walletType=%s",

  // rollup - 롤업
  getRollupLog:
    "/rollup/log?pageSize=%s&pageNum=%s&type=%s&statusType=%s&startDate=%s&endDate=%s&searchText=%s",
  getRollupLogSelect:
    "/rollup/reward/log?pageSize=%s&pageNum=%s&idx=%s&rewardType=%s",
  getRollupLogHoldSelect:
    "/rollup/hold/log?pageSize=%s&pageNum=%s&idx=%s&rewardType=%s",
  rollupUser: "/rollup/user/select/%s",
  getRollupUserWalletList:
    "/rollup/walletListByIdx?pageSize=%s&pageNum=%s&idx=%s",
  getRollupUserRewardLog: "/rollup/select/reward/log?recommendCode=%s",
  getRollupUserRewardLogList:
    "/rollup/select/reward/log/list?pageSize=%s&pageNum=%s&recommendCode=%s&rewardType=%s",
  getRollupUserHoldLog: "/rollup/hold/%s",
  getRollupUserHoldLogList:
    "/rollup/hold/to/log?pageSize=%s&pageNum=%s&recommendCode=%s&rewardType=%s&hold=%s",
  getRollupUserRecommendList:
    "/rollup/select/recommend?pageSize=%s&pageNum=%s&recommendCode=%s",

  // share - 공유
  getShareLog:
    "/share/log?pageSize=%s&pageNum=%s&startDate=%s&endDate=%s&periodType=%s",
  shareDetailList:
    "/share/log/detail/list?pageSize=%s&pageNum=%s&idx=%s&date=%s&periodType=%s",
  shareLevelUserList:
    "/share/level/user/list?pageSize=%s&pageNum=%s&idx=%s&level=%s",

  // hold - 보류
  getRollupHold:
    "/reward/hold?pageSize=%s&pageNum=%s&rewardType=%s&hold=%s&startDate=%s&endDate=%s",
  getRollupHoldLogSelect:
    "/reward/hold/to/log?pageSize=%s&pageNum=%s&recommendCode=%s&rewardType=%s&hold=%s",
  getRollupHoldUserSelect: "/reward/hold/%s",

  // swap - 스왑
  getSwapLogList:
    "/swap/log?pageSize=%s&pageNum=%s&statusType=%s&searchText=%s&startDate=%s&endDate=%s",
  swapUserSelect: "/swap/select/%s",
  getSwapWalletList: "/swap/walletListByIdx?pageSize=%s&pageNum=%s&idx=%s",
  getSwapRewardLog: "/swap/select/reward/log?recommendCode=%s",
  getSwapRewardLogList:
    "/swap/select/reward/log/list?pageSize=%s&pageNum=%s&recommendCode=%s&rewardType=%s",
  getSwapHoldLog: "/swap/hold/%s",
  getSwapHoldLogList:
    "/swap/hold/to/log?pageSize=%s&pageNum=%s&recommendCode=%s&rewardType=%s&hold=%s",
  getSwapRecommendList:
    "/swap/select/recommend?pageSize=%s&pageNum=%s&recommendCode=%s",

    // statistics - 통계
    getStatisticsLevel: "/statistics/levelUp",
  getStatisticsUserList:
    "/statistics/levelUp/Log?pageSize=%s&pageNum=%s&periodType=%s&startDate=%s&endDate=%s",
  getStatisticsUserSelectList:
    "/statistics/levelUp/user/list?pageSize=%s&pageNum=%s&periodType=%s&level=%s&statDate=%s&avatar=%s",
  getStatisticsRollup: "/statistics/rollup",
  getStatisticsRollupList:
    "/statistics/rollup/Log?pageSize=%s&pageNum=%s&periodType=%s&startDate=%s&endDate=%s",
  getStatisticsRecRollup: "/statistics/recRollup",
  getStatisticsRecRollupList:
    "/statistics/recRollup/Log?pageSize=%s&pageNum=%s&periodType=%s&startDate=%s&endDate=%s",
  getStatisticsShare: "/statistics/share",
  getStatisticsHold: "/statistics/reward/hold",
  getStatisticsVirtualAccountDeposit: "/statistics/virtualAccountDeposit",
  getStatisticsVirtualAccountDepositList:
    "/statistics/virtualAccountDeposit/Log?pageSize=%s&pageNum=%s&periodType=%s&startDate=%s&endDate=%s",

  // event - 이벤트
  getEventLog:
    "/event/log?pageSize=%s&pageNum=%s&startDate=%s&endDate=%s&searchType=%s&searchText=%s",
  getEventRank:
    "/event/recommend/rank?pageSize=%s&pageNum=%s&searchType=%s&searchText=%s",
  getEventInfo: "/event/recommend/info?pageSize=%s&pageNum=%s&recommendCode=%s",

  // wallet - 지갑
  feeWalletList: "/wallet/feeWallet/list?type=%s&deleted=%s&searchText=%s",
  feeWalletCreate: "/wallet/feeWallet/create",
  feewWalletUpdate: "/wallet/feeWallet/update",
  feeWalletDelete: "/wallet/feeWallet/delete/%s",
  getCoinBalance: "/wallet/coin/balance",

  // coin - 코인
  distributeList:
    "/coin/airDropLogList?pageSize=%s&pageNum=%s&type=%s&searchText=%s",
  manualCreate: "/coin/manual/excel",
  manualSingleCreate: "/coin/manual/single",
  dailyLockSet: "/foundation/decentral/set/dailyLock",
  dailyLockSetExcel: "/foundation/decentral/set/dailyLock/excelUpload",

  // foundation - 재단
  getFoundationCount: "/foundation/list/user/count?coinType=%s",
  foundationUserList:
    "/foundation/list/user?pageSize=%s&pageNum=%s&coinType=%s&orderByType=%s&searchText=%s",
  decentralFoundationListExcel:
    "/foundation/list/user/decentral/excel?pageSize=%s&pageNum=%s&coinType=%s&orderByType=%s&searchText=%s",
  getDailyLockList:
    "/foundation/decentral/get/daily/lock?coinType=%s&searchType=%s&searchText=%s",
  getDailyLockRequestList:
    "/foundation/decentral/list/dailyLockRequest?pageSize=%s&pageNum=%s&lockType=%s&statusType=%s&searchType=%s&searchText=%s",
  unDailyLock: "/foundation/decentral/undailyLock",
  getLockList:
    "/foundation/decentral/get/lock?coinType=%s&searchType=%s&searchText=%s",
  unLock: "/foundation/decentral/unLock",

  foundationLockList:
    "/foundation/lockList?pageSize=%s&pageNum=%s&coinType=%s&searchText=%s&orderType=%s&orderByType=%s&startDate=%s&endDate=%s",
  foundationLockExcel:
    "/foundation/lockList/excel?pageSize=%s&pageNum=%s&coinType=%s&searchText=%s&orderType=%s&orderByType=%s&startDate=%s&endDate=%s",
  foundationLockCreateExcel: "/foundation/lock/excelUpload",
  lockUpdate: "/foundation/lock/update",
  lockDelete: "/foundation/lock/delete",
  lockMuptiDelete: "/foundation/lockMultipleDelete",

  // transaction - 거래
  getTransferRequestList:
    "/transaction/transferRequestList?pageSize=%s&pageNum=%s",
  getTransferLog:
    "/transaction/transferLogList?pageSize=%s&pageNum=%s&coinType=%s&requestType=%s&searchType=%s&startDate=%s&endDate=%s&searchText=%s&transferType=%s",
  downloadTransferLogExcel:
    "/transaction/transferLogList/excel?pageSize=%s&pageNum=%s&coinType=%s&requestType=%s&searchType=%s&startDate=%s&endDate=%s&searchText=%&transferType=%s",

  // community - 게시글
  noticeList:
    "/community/notice/list?pageSize=%s&pageNum=%s&deleted=%s&category=%s&searchText=%s",
  noticeCreate: "/community/notice/create",
  noticeUpdate: "/community/notice/update",
  noticeDelete: "/community/notice/delete/%s",
  faqList:
    "/community/faq/list?pageSize=%s&pageNum=%s&deleted=%s&searchText=%s",
  faqCreate: "/community/faq/create",
  faqUpdate: "/community/faq/update",
  faqDelete: "/community/faq/delete/%s",

  // system - 시스템
  adminList: "/system/admin/list?pageSize=%s&pageNum=%s&searchText=%s",
  adminCreate: "/system/admin/create",
  adminUpdate: "/system/admin/update",
  adminDelete: "/system/admin/delete/%s",
  coinAuthList: "/system/admin/authCoinDetail/%s",
  authList: "/system/admin/authDetail/%s",
  authUpdate: "/system/admin/authUpdate",
  authCoinUpdate: "/system/admin/authCoinUpdate",

  // adminUserInfo - 내정보
  adminUserUpdate: "/myPage/update",
  otpUpdate: "/myPage/otp/change/key",

  //image
  imageUpload: "/image/upload",
  getImage: "/image/",
  imageEncoding: "/image/imageEncoding"
};

const imageType = ["image/jpg", "image/jpeg", "image/png", "image/bmp"];

export {
  serverUrl,
  httpExec,
  makeUrl,
  httpGet,
  httpUrl,
  httpPut,
  httpPost,
  httpDownload,
  imageType
};
