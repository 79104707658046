import React, { Component } from "react";

import { Modal, Table } from "antd";
import { httpGet, httpUrl } from "../../api/httpClient";
import { amountDivide, checkData, formatDate } from "../../util";
import string from "../../string";

class RollupLogSelect extends Component {
  state = {
    list: [],
    pagination: {
      total: 0,
      current: 0
    }
  };

  // 페이징 처리
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({ pagination: pager });

    const { pageType } = this.props;

    pageType === "user"
      ? this.getUserRewardLogList({ pageNum: pager.current })
      : this.getRollupLogSelect({ pageNum: pager.current });
  };

  // 회원관리 지급내역 상세 - 정렬 시 날짜기준
  getUserRewardLogList = (params = {}) => {
    const { page, recommendCode, type } = this.props;
    let pageNum = params.pageNum || 1;
    let url = "";
    if (page === "user") url = httpUrl.getUserRewardLogList;
    if (page === "rollup") url = httpUrl.getRollupUserRewardLogList;
    if (page === "swap") url = httpUrl.getSwapRewardLogList;

    httpGet(url, [10, pageNum, recommendCode, type], {})
      .then((res) => {
        // console.log(`user reward`);
        // console.log(`user reward res = ${JSON.stringify(res, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.list === null ? [] : res.data.list,
          pagination
        });
      })
      .catch((e) => {});
  };

  // 롤업내역 상세 - 정렬시 단계기준
  getRollupLogSelect = (params = {}) => {
    const { idx, type } = this.props;
    let pageNum = params.pageNum || 1;

    httpGet(httpUrl.getRollupLogSelect, [10, pageNum, idx, type], {})
      .then((res) => {
        // console.log(`rollup reward`);
        // console.log(`rollup res = ${JSON.stringify(res, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.list === null ? [] : res.data.list,
          pagination
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    const { pageType } = this.props;

    pageType === "user"
      ? this.getUserRewardLogList()
      : this.getRollupLogSelect();
  }

  render() {
    const { visible, type, onCancel } = this.props;
    const { list, pagination } = this.state;

    const columns = [
      {
        key: "createData",
        title: "날짜",
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: "TYPE",
        dataIndex: "rewardType",
        className: "text-center",
        render: (data) => <div>{string.rollupTypeString[data]}</div>
      },
      {
        title: "FROM",
        dataIndex: "from",
        className: "text-center",
        render: (data, row) => (
          <div>
            {data === null ? checkData(data) : `${row.fromId} (${data})`}
          </div>
        )
      },
      {
        title: "TO",
        dataIndex: "to",
        className: "text-center",
        render: (data, row) => (
          <div>
            {row.toId} ({data})
          </div>
        )
      },
      {
        title: "수량",
        dataIndex: "amount",
        className: "text-center",
        render: (data) => <div>{amountDivide(data)}</div>
      },
      {
        width: 300,
        title: "TX_ID",
        dataIndex: "trxId",
        className: "text-center",
        render: (data) => (
          <div
            className="text-ellipsis"
            style={{ width: "300px", whiteSpace: "normal" }}
          >
            {checkData(data)}
          </div>
        )
      },
      {
        title: "상태",
        dataIndex: "status",
        className: "text-center",
        render: (data) => <div>{string.requestLogString[data]}</div>
      },
      {
        title: "단계",
        dataIndex: "depth",
        className: "text-center"
      }
    ];

    return (
      <Modal
        visible={visible}
        title={`${
          type === "ROLL_UP"
            ? "롤업"
            : type === "REC_ROLL_UP"
            ? "추천 롤업"
            : type === "RECOMMEND"
            ? "추천"
            : "보류"
        } 정보`}
        onCancel={onCancel}
        footer={null}
        style={{ minWidth: "1400px" }}
      >
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </Modal>
    );
  }
}

export default RollupLogSelect;
