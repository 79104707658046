import React, { Component } from "react";

import { Button, DatePicker, Input, Table } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";

import { httpGet, httpUrl } from "../../api/httpClient";
import {
  amountDivide,
  checkData,
  endDateFormat,
  formatDate,
  startDateFormat
} from "../../util";
import string from "../../string";
import SelectBox from "../../components/common/SelectBox";
import UserLevelColor from "../../components/user/UserLevelColor";
import RollupLogHoldSelect from "../../components/rollup/RollupLogHoldSelect";

const Search = Input.Search;
const RangePicker = DatePicker.RangePicker;
class HoldLog extends Component {
  state = {
    list: [],
    pagination: {
      total: 0,
      current: 0
    },
    rewardType: "ALL",
    hold: "ALL",
    startDate: "",
    endDate: ""
  };

  // 페이징 처리
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({ pagination: pager });

    this.getHoldLog({ pageNum: pager.current });
  };

  // 검색 기간 설정
  handleChangeDate = (date) => {
    this.setState({
      startDate: date.length !== 0 ? startDateFormat(date[0]) : "",
      endDate: date.length !== 0 ? endDateFormat(date[1]) : ""
    });
  };

  // 타입 선택
  rewardTypeHandleChange = (value) => {
    this.setState({
      rewardType: value
    });
  };

  // HOLD 선택
  holdTypeHandleChange = (value) => {
    this.setState({
      hold: value
    });
  };

  // 보류내역
  getHoldLog = (params = {}) => {
    const { rewardType, hold, startDate, endDate } = this.state;
    let pageNum = params.pageNum || 1;

    httpGet(
      httpUrl.getRollupHold,
      [10, pageNum, rewardType, hold, startDate, endDate],
      {}
    )
      .then((res) => {
        // console.log(`res = ${JSON.stringify(res, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.list === null ? [] : res.data.list,
          pagination
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getHoldLog();
  }

  render() {
    const { rewardType, hold, list, pagination } = this.state;
    const dateFormat = "YYYY-MM-DD";

    const columns = [
      {
        key: "idx",
        title: "날짜",
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        key: "id",
        title: "ID (이름)",
        dataIndex: "id",
        className: "text-center",
        render: (data, row) => `${data} (${checkData(row.name)})`
      },
      {
        title: "TYPE",
        dataIndex: "rewardType",
        className: "text-center",
        render: (data) => <div>{string.rollupTypeSelectString[data]}</div>
      },
      {
        title: "수량",
        dataIndex: "amount",
        className: "text-center",
        render: (data, row) => <div>{amountDivide(data)}</div>
      },
      {
        title: "단계",
        dataIndex: "depth",
        className: "text-center"
      },
      {
        title: "필요등급",
        dataIndex: "level",
        className: "text-center",
        render: (data, row) => (
          <UserLevelColor
            data={{ level: data }}
            style={{
              padding: 10,
              border: "1px solid",
              borderRadius: 5
            }}
          />
        )
      },
      {
        title: "기타",
        dataIndex: "holdFlag",
        className: "text-center",
        render: (data) => <div>{string.holdFlagString[data]}</div>
      }
    ];

    return (
      <div>
        {/* 회원 검색 */}
        <div className="flex-row flex-center flex-sb">
          <div className="title" style={{ marginTop: 30 }}>
            회원 검색
          </div>
          <Search
            placeholder="아이디 검색해주세요."
            enterButton="검색"
            allowClear
            onSearch={this.onSearch}
            style={{ width: "300px" }}
          />
        </div>
        {this.renderUser()}
        <div className="m-b-20" style={{ clear: "both" }} />
        {/* 보류내역 */}
        <div className="title">보류 내역</div>
        <div className="flex-row flex-center m-b-10 flex-end">
          <label htmlFor="rewardType">
            TYPE&nbsp;
            <SelectBox
              value={rewardType}
              style={{ width: "160px", marginRight: "10px" }}
              code={string.rollupTypeSelectString}
              codeString={string.rollupTypeSelectString}
              onChange={(value) => {
                if (value !== rewardType) {
                  this.rewardTypeHandleChange(value);
                }
              }}
            />
          </label>
          <label htmlFor="rewardType">
            기타&nbsp;
            <SelectBox
              value={hold}
              style={{ width: "160px", marginRight: "10px" }}
              code={string.holdTypeString}
              codeString={string.holdTypeString}
              onChange={(value) => {
                if (value !== hold) {
                  this.holdTypeHandleChange(value);
                }
              }}
            />
          </label>
          <label>검색기간&nbsp;</label>
          <RangePicker
            locale={locale}
            format={dateFormat}
            onChange={(date) => {
              this.handleChangeDate(date);
            }}
          />
          <Button
            onClick={() => {
              this.getHoldLog();
            }}
          >
            검색
          </Button>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
        <div style={{ clear: "both", marginBottom: 10 }} />
      </div>
    );
  }

  // 회원 검색
  onSearch = (value) => {
    this.setState(
      {
        searchText: value
      },
      () => {
        httpGet(httpUrl.getRollupHoldUserSelect, [value], {})
          .then((res) => {
            // console.log(`res = ${JSON.stringify(res, null, 4)}`);
            this.setState({
              userList: [res.data]
            });
          })
          .catch((e) => {});
      }
    );
  };

  renderUser() {
    const { visible, recommendCode, userRewardType, userHold, userList } =
      this.state;

    const column = [
      {
        key: "id",
        title: "ID",
        dataIndex: "id",
        className: "text-center",
        render: (data) => checkData(data)
      },
      {
        title: "이름",
        dataIndex: "name",
        className: "text-center",
        render: (data) => checkData(data)
      },
      {
        title: () => (
          <div>
            <div>롤업</div>
            <div>(건수 / 수량)</div>
          </div>
        ),
        dataIndex: "rollupAmount",
        className: "text-center",
        render: (data, row) =>
          data === 0 ? (
            <div>
              {row.rollupCount} / {amountDivide(data)}
            </div>
          ) : (
            <div
              className="pointer"
              onClick={() => {
                this.setState({
                  visible: true,
                  recommendCode: row.recommendCode,
                  userRewardType: "ROLL_UP",
                  userHold: "NORMAL"
                });
              }}
            >
              {row.rollupCount} / {amountDivide(data)}
            </div>
          )
      },
      {
        title: () => (
          <div>
            <div>추천 롤업</div>
            <div>(건수 / 수량)</div>
          </div>
        ),
        dataIndex: "recRollupAmount",
        className: "text-center",
        render: (data, row) =>
          data === 0 ? (
            <div>
              {row.recRollupCount} / {amountDivide(data)}
            </div>
          ) : (
            <div
              className="pointer"
              onClick={() => {
                this.setState({
                  visible: true,
                  recommendCode: row.recommendCode,
                  userRewardType: "REC_ROLL_UP",
                  userHold: "NORMAL"
                });
              }}
            >
              {row.recRollupCount} / {amountDivide(data)}
            </div>
          )
      },
      {
        title: () => (
          <div>
            <div>지급제외</div>
            <div>(건수 / 수량)</div>
          </div>
        ),
        dataIndex: "holdAmount",
        className: "text-center",
        render: (data, row) =>
          data === 0 ? (
            <div>
              {row.holdCount} / {amountDivide(data)}
            </div>
          ) : (
            <div
              className="pointer"
              onClick={() => {
                this.setState({
                  visible: true,
                  recommendCode: row.recommendCode,
                  userRewardType: "ALL",
                  userHold: "HOLD"
                });
              }}
            >
              {row.holdCount} / {amountDivide(data)}
            </div>
          )
      },
      {
        title: "합계",
        dataIndex: "amount",
        className: "text-center",
        render: (data) => <div>{amountDivide(data)}</div>
      }
    ];

    return (
      <div>
        {/* 상세내역 */}
        {visible && (
          <RollupLogHoldSelect
            page="hold"
            pageType="user"
            recommendCode={recommendCode}
            visible={visible}
            type={userRewardType}
            hold={userHold}
            onCancel={() => {
              this.setState({ visible: false });
            }}
          />
        )}
        <Table
          rowKey={(record) => record.idx}
          columns={column}
          dataSource={userList}
          pagination={false}
        />
      </div>
    );
  }
}

export default HoldLog;
