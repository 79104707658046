import React, { Component } from "react";

import { Button, Card, DatePicker, Table } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";

import { httpGet, httpUrl } from "../../api/httpClient";

import { dayFormat, statFormat } from "../../util";
import string from "../../string";
import UserModal from "../../components/user/UserModal";
import SelectBox from "../../components/common/SelectBox";
import UserLevelCount from "../../components/statistics/UserLevelCount";
import UserTotalCountColor from "../../components/statistics/UserTotalCountColor";
import UserLevelCountColor from "../../components/statistics/UserLevelCountColor";

const RangePicker = DatePicker.RangePicker;
class LevelStatistics extends Component {
  state = {
    list: [],
    pagination: {
      total: 0,
      current: 0
    },
    periodType: "DAY",
    startDate: "",
    endDate: "",

    levelData: {
      member: { count: 0 },
      owner: { count: 0 },
      total: { count: 0 },
      today: { count: 0 }
    }
  };

  // 페이징 처리
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({ pagination: pager });

    this.getStatisticsUserList({ pageNum: pager.current });
  };

  // 검색 기간 설정
  handleChangeDate = (date) => {
    this.setState({
      startDate: date.length !== 0 ? statFormat(date[0]) : "",
      endDate: date.length !== 0 ? statFormat(date[1]) : ""
    });
  };

  // 주기 선택
  handleChangePeriodType = (value) => {
    this.setState({
      periodType: value
    });
  };

  // 현황 목록
  getStatisticsUserList = (params = {}) => {
    const { periodType, startDate, endDate } = this.state;
    let pageNum = params.pageNum || 1;

    httpGet(
      httpUrl.getStatisticsUserList,
      [10, pageNum, periodType, startDate, endDate],
      {}
    )
      .then((res) => {
        // console.log(`userList = ${JSON.stringify(res, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.list === null ? [] : res.data.list,
          pagination
        });
      })
      .catch((e) => {});
  };

  // 현황 통계
  getStatisticsLevel = () => {
    httpGet(httpUrl.getStatisticsLevel, [], {})
      .then((res) => {
        // console.log(`userCount = ${JSON.stringify(res, null, 4)}`);
        let levelData = res.data;
        this.setState({
          levelData
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    // console.log(`props = ${JSON.stringify(this.props, null, 4)}`);
    this.getStatisticsLevel();
    this.getStatisticsUserList();
  }

  render() {
    const { visible, type, statDate, periodType, levelData, list, pagination } =
      this.state;
    const dateFormat = "YYYY-MM-DD";

    const columns = [
      {
        key: "statDate",
        title: "날짜",
        dataIndex: "statDate",
        className: "text-center",
        render: (data) => dayFormat(data)
      },
      {
        title: "MEMBER",
        dataIndex: "member",
        className: "text-center",
        render: (data, row) => (
          <UserLevelCount
            data={data}
            className="pointer"
            onClick={() => {
              this.setState({
                visible: true,
                type: "MEMBER",
                statDate: row.statDate
              });
            }}
          />
        )
      },
      {
        title: "OWNER",
        dataIndex: "owner",
        className: "text-center",
        render: (data, row) => (
          <UserLevelCount
            data={data}
            className="pointer"
            onClick={() => {
              this.setState({
                visible: true,
                type: "OWNER",
                statDate: row.statDate
              });
            }}
          />
        )
      }
    ];

    return (
      <div>
        {visible && (
          <UserModal
            visible={visible}
            type={type}
            page="statistics"
            statDate={statDate}
            periodType={periodType}
            onCancel={() => {
              this.setState({ visible: false });
            }}
          />
        )}

        <div className="flex-row flex-center">
          <Card
            style={{
              border: "1px dotted #A6A6A6",
              borderRadius: 20,
              marginRight: 20
            }}
          >
            <div className="flex-row flex-center">
              <UserTotalCountColor
                title="총 등록 회원"
                data={levelData.total}
                style={{ marginRight: 30 }}
              />
              <UserTotalCountColor
                title="오늘 등록한 회원수"
                data={levelData.today}
              />
            </div>
          </Card>
          <Card
            style={{
              border: "1px dotted #A6A6A6",
              borderRadius: 20
            }}
          >
            <div className="flex-row flex-center flex-sb">
              <UserLevelCountColor
                level={3}
                data={levelData.member}
                style={{ marginRight: 30 }}
              />
              <UserLevelCountColor level={4} data={levelData.owner} />
            </div>
          </Card>
        </div>
        <div className="flex-row flex-center m-b-20" />
        <div className="title">회원 현황</div>
        <div className="flex-row flex-center m-b-10 flex-end">
          <label className="flex-row flex-center m-r-10">
            주기&nbsp;&nbsp;
            <SelectBox
              className="m-r-10"
              style={{ width: "160px" }}
              value={string.partnerStatistics[periodType]}
              codeString={string.partnerStatistics}
              onChange={this.handleChangePeriodType}
            />
          </label>
          <label>
            검색기간&nbsp;
            <RangePicker
              locale={locale}
              format={dateFormat}
              onChange={(date) => {
                this.handleChangeDate(date);
              }}
            />
            <Button
              onClick={() => {
                this.getStatisticsUserList();
              }}
            >
              검색
            </Button>
          </label>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.statDate}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default LevelStatistics;
