import React, { Component } from "react";

import { checkData } from "../../util";

class UserLevelCount extends Component {
  componentDidMount() {}

  render() {
    const { onClick, className, data } = this.props;
    return (
      <div className={`flex-row flex-center justify-center`}>
        {data.count === 0 ? (
          checkData(data.count)
        ) : (
          <div onClick={onClick} className={className}>
            {data.count}
          </div>
        )}
      </div>
    );
  }
}

export default UserLevelCount;
