import React, { Component } from "react";

import { Input, DatePicker, Table } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";

import { httpGet, httpUrl } from "../../api/httpClient";
import {
  checkData,
  formatDate,
  numberFormat,
  startDateFormat,
  endDateFormat,
  amountDivide
} from "../../util";
import string from "../../string";
import SelectBox from "../../components/common/SelectBox";

const Search = Input.Search;
const RangePicker = DatePicker.RangePicker;
class EventLogList extends Component {
  state = {
    list: [],
    pagination: {
      total: 0,
      current: 0
    },
    startDate: "",
    endDate: "",
    searchType: "NAME",
    searchText: "",

    type: "create",
    visible: false,
    selectedRow: {},
    isLoaded: false 
  };

  // 페이징 처리
  handleTableChange = (pagination, filters, sorter) => {  
    if (this.state.isLoaded) alert("조회버튼  을 먼저 클릭해주세요.");
    else {
      const pager = { ...this.state.pagination };
      pager.current = pagination.current;
      this.setState({ pagination: pager });

      this.getEventLog({ pageNum: pager.current });
    }
  };

  // 검색 기간 설정
  handleChangeDate = (date) =>  {  
    this.setState({
      startDate: date.length !== 0 ? startDateFormat(date[0]) : "",
      endDate: date.length !== 0 ? endDateFormat(date[1]) : "",
      isLoaded: true
    });
  };

  // 검색 대상 설정
  searchTypeHandleChange = (value) => {
    // console.log(`checked = ${JSON.stringify(e.target.checked)}`);
    this.setState({
      searchType: value,
      isLoaded: true
    });
  };

  // 검색
  onSearch = (value) => {
    this.setState(
      {
        searchText: value,
        isLoaded: true
      },
      () => {
        this.getEventLog();
      }
    );
  };

  getEventLog = (params = {}) => {
    const { startDate, endDate, searchType, searchText } = this.state;

    let pageNum = params.pageNum || 1;

    httpGet(
      httpUrl.getEventLog,
      [10, pageNum, startDate, endDate, searchType, searchText],
      {}
    )
      .then((res) => {
        // console.log(`data= ${JSON.stringify(res.data, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.list === null ? [] : res.data.list,
          pagination,
          isLoaded: false
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getEventLog();
  }

  render() {
    const { searchType, list, pagination } = this.state;
    const dateFormat = "YYYY-MM-DD";

    const columns = [
      {
        title: "요일",
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        key: "rank",
        title: "RANK",
        dataIndex: "rank",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "ID",
        dataIndex: "id",
        className: "text-center"
      },
      {
        title: "이름",
        dataIndex: "name",
        className: "text-center",
        render: (data, row) => (
          <div>
            {data} ({row.recommendCode})
          </div>
        )
      },
      // {
      //   title: "type",
      //   dataIndex: "type",
      //   className: "text-center"
      // },
      {
        title: "amount",
        dataIndex: "amount",
        className: "text-center",
        render: (data, row) => <div>{amountDivide(data)}</div>
      },
      {
        width: 400,
        title: "TX_ID",
        dataIndex: "txId",
        className: "text-center",
        render: (data) => (
          <div
            style={{
              minWidth: 60,
              maxWidth: 400,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "normal"
            }}
          >
            {checkData(data)}
          </div>
        )
      },
      {
        title: "상태",
        dataIndex: "status",
        className: "text-center",
        render: (data) => <div>{string.requestLogString[data]}</div>
      }
    ];

    return (
      <div>
        <div className="title">   지급 내역</div>
        <div className="flex-row flex-center m-b-10 flex-end">
          <label htmlFor="searchType" className="m-r-10">
            기간&nbsp;
            <RangePicker
              locale={locale}
              format={dateFormat}
              onChange={(date) => {
                this.handleChangeDate(date);
              }}
            />
          </label>  
          <label htmlFor="searchType" className="m-r-10">
            대상&nbsp;
            <SelectBox
              value={searchType}
              style={{ width: "160px" }}
              code={string.eventSearchTypeString}
              codeString={string.eventSearchTypeString}
              onChange={(value) => {
                if (value !== searchType) {
                  this.searchTypeHandleChange(value);
                }
              }}
            />
          </label>
          <Search
            placeholder={`검색어를 입력해주세요.`}
            enterButton="검색"
            allowClear
            onSearch={this.onSearch}
            style={{ width: 300 }}
          />
        </div>
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default EventLogList;
